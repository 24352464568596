
import { Vue, Component } from 'vue-property-decorator'

@Component({
  data() {
    return {
      siteId: process.env.siteId,
      brand: process.env.brand
    }
  },
  props: {
    endpoint: {
      type: String,
      // eslint-disable-next-line no-script-url
      default: "javascript:document.getElementById('address-search').focus()"
    }
  }
})
export default class AddressBar extends Vue {
}
