
import { Vue, Component } from 'vue-property-decorator'
import LottieAnimation from 'lottie-web-vue'


@Component({
  components: {
    LottieAnimation
  }
})

export default class RedirectionLoading extends Vue {
}
